








import Vue from 'vue';
import KkCardProjects from '@/components/parts/KkCardProjects.vue';
import Footer from '@/components/parts/Footer.vue';
import ContactBanner from '@/components/interaction/ContactBanner.vue';

import { works } from '@/data/works.ts';

export default Vue.extend({
  name: 'Projects',
  components: {
    KkCardProjects,
    Footer,
    ContactBanner
  },
  data() {
      return {
          tempTexts: [
              {
                  title: 'Síntesis',
                  desc: 'Síntesis del proyecto de Kraken Labs'
              },
              {
                  title: 'Tecnologías',
                  desc: 'Tecnolgías usadas en el proyecto de Kraken Labs'
              }
          ],
          logo: require('@/assets/img/kkweb.svg'),
          img: require('@/assets/img/setup.jpg'),
          indicator: '- PROYECTOS - PROJECTS -',
          projectTitle: 'Título del proyecto'
      }
  },
  created() {
    const project = works[0].filter( (work: { name: string } ) => work.name == this.$route.params.liName )[0];
    this.$data.projectTitle      = project.name;
    this.$data.img               = project.img;
    this.$data.tempTexts[0].desc = project.desc;
    this.$data.tempTexts[1].desc = "<ul class='tech-ul'>";
    this.$data.tempTexts[1].desc += project.techs.map( tech => `<li class="tech-li">${ tech }</li> `).join(' ');
    this.$data.tempTexts[1].desc += '</ul>';
  }
})
