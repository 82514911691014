














import Vue from 'vue';
import KkCard from '@/components/parts/KkCard.vue';
import Footer from '@/components/parts/Footer.vue';
import ContactBanner from '@/components/interaction/ContactBanner.vue';

export default Vue.extend({
  name: 'About',
  components: {
    KkCard,
    Footer,
    ContactBanner
  },
  data() {
      return {
          tempTexts: [
              {
                  title: 'Proyecto',
                  desc: 'Kraken Labs como proyecto nace de la necesidad de la <b>especialización de la creación de sitios y aplicaciones web en un alto nivel de detalle en el usuario</b>. <br>En la actualidad estamo acostumbrados a visitar cientos de sitios web que parecen calcos unos de otros y que dificilmente permiten dar un extra de calidad a los usuarios que permita diferenciarse de la competencia. <br> Ante esta necesidad, <b>Kraken Labs busca la busqueda de la excelencia con un sistema totalmente enfocado a la satisfación del cliente final</b>.'
              },
              {
                  title: 'Misión',
                  desc: 'La misión de Kraken Labs no es otra que la de ofrecer un <b>producto final de alto valor</b>, capaz de ser utilizado como una <b>herramienta productiva para el negocio y con alto nivel de atención en los detalles</b>. Permitiendo de esta manera una diferenciación total del resto de la competencia estableciendo una experiencia única a los visitantes y usuarios de nuestro sitio que dificilmente podrán pasar desapercibidos entre el resto de competidores del sector.'
              },
              {
                  title: 'Visión',
                  desc: 'Kraken Labs existe con el objetivo de <b>crear excelentes productos de software apostando por la innovación</b>. Con <b>mayor enfoque en la calidad que en la cantidad</b>, la prioridad es la satisfacción del cliente mediante un alto nivel de atención en los detalles que marcan la diferencia, haciendo de la web un espacio de alto valor y un enfoque en la <b>creación de relaciones a largo plazo con beneficios para todas las partes implicadas</b>.'
              },
              {
                  title: 'Valores',
                  desc: 'Los valores de Kraken Labs pueden destacarse por la <b>búsqueda de la excelencia en cada uno de los productos ofrecidos</b>, la <b>transparencia en el trato con el cliente</b> y el aporte de <b>herramientas y contenido de calidad</b>, facilitando el acceso a las nuevas tecnologías mediante un software de alto valor y con capacidad de adaptación a las diversas necesidades del entorno laboral.<br> Sin duda <b>lo más importante es quedes encantado con el trabajo realizado</b> y ofrecerte la atención y el enfoque para que puedas <b>destacar en el mundo online con toda confianza</b>.'
              }
          ],
          logo: require('@/assets/img/kkweb.svg'),
          img: require('@/assets/img/setup.jpg'),
          indicator: '- ABOUT - ACERCA DE -'
      }
  }
})
