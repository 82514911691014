export const cookiesTexts = [
    {
        title: '¿Qué son las cookies?',
        desc: '<p>Un cookie es un pequeño archivo de texto enviado por un sitio web que se descargan e instalan en su ordenador, móvil o tableta a través de tu navegador y almacenado en el navegador del usuario, de manera que el sitio web puede consultar la actividad previa del usuario.        Tipo de cookies en esta Web según su función        Cookies propias: Son las creadas por el responsable de la página Web        Cookies de terceros: Son aquellas que se envían a su equipo desde un equipo o dominio que no es gestionado por el responsable de la Web, sino por otra entidad colaboradora. Cookies de sesión: Son las diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.</p>'
    },
    {
        title: 'Tipos de cookies',
        desc: '<p>Cookies persistentes: Son aquellas en que los datos siguen almacenados en el terminal pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</p><p>Cookies de análisis Son aquéllas que permiten el seguimiento y análisis del comportamiento de los visitantes de un sitio web. La información recogida mediante este tipo de cookies se utiliza para la medición de la actividad del sitio web y para la elaboración de estadísticas con el fin de mejorar el sitio web.</p><p>A continuación le indicamos las cookies específicas que utilizamos en https://chemaalfonso.com y su finalidad:</p>'
    },
    {
        title: 'Tipo: Cookie propia',
        desc: '<p>DEFINICION Y FINALIDAD: Utilizamos una herramienta que instala una cookie cuando usted acepta nuestra política de cookies con el fin de recordar si nos vuelve a visitar su aceptación. Si deseas desinstalar esta cookie se lo indicamos abajo en el apartado Desactivación de las cookies. Esta cookie la utilizamos para recordar que has aceptado la Política de Cookies.NOMBRE Y TIEMPO DE PERMANENCIA: cookie_notice_accepted que expira al mes.</p>'
    },
    {
        title: 'NOMBRE TIEMPO DE PERMANENCIA DESCRIPCIÓN',
        desc: '<p> _ga 2 años Se usa para distinguir a los usuarios <br>_gat 10 minutos <br>Se usa para limitar el porcentaje de solicitudes</p>'
    },
    {
        title: 'Consentimiento',
        desc: '<p>Para que poder instalar las cookies en su ordenador debemos contar con su consentimiento y éste se puede solicitar expresamente o bien, una vez informado, si decides continuar con su visita a nuestra Web consideraremos que nos da dicho consentimiento. </p>'
    },
    {
        title: 'Revocación del consentimiento',
        desc: '<p>Si más adelante quiere revocar el consentimiento tan solo debe pinchar el botón residual de cookies, ir a más información y configurar su navegador para que rechace las cookies y también deberá borrar el historial para eliminar las ya instaladas. Si por cualquier motivo no quiere que se instalen cookies en su ordenador tenemos obligación de informarte sobre cómo hacerlo y para ello deberás configurar tu navegador. A continuación te facilitamos los enlaces para la configuración de las cookies en los principales navegadores.</p>'
    },
    {
        title: 'Cómo gestionar las cookies',
        desc: '<p>Para permitir, conocer, bloquear o eliminar las cookies instaladas en su equipo puede hacerlo mediante la configuración de las opciones del navegador instalado en su ordenador. Por ejemplo puede encontrar información sobre cómo hacerlo como hacerlo en el caso que use como navegador:<br>Firefox desde http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web<br>Chrome desde http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647<br>Explorer desde http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9<br>Safari desde http://support.apple.com/kb/ph5042</p>'
    }
];
                