






































import Vue from 'vue';
import KkCard from '@/components/parts/KkCard.vue';
import Footer from '@/components/parts/Footer.vue';
import Swal from 'sweetalert2';

declare global {
  // eslint-disable-next-line 
  interface Window { gtag: any }
}

export default Vue.extend({
  name: 'Contact',
  components: {
    KkCard,
    Footer
  },
  data() {
      return {
        whatsappMessage: '',
        tempTexts: [
            {
                title: 'Consigue',
                desc: 'Una <b>valoración sin compromiso para tu proyecto</b>, se estudiará tu caso y se te ofrecerá asesoramiento sobre la mejor forma de materializar tu idea, ofrecientote un gran abanico de posibilidades entre las que tu <b>serás participe de las decisiones</b>.'
            },
            {
                title: 'Avanza',
                desc: 'Contacta con Kraken Labs y <b>hazte con una web única y diferenciadora de tu competencia</b>, con <b>alto nivel de detalle</b> y obteniendo <b>datos y analíticas detallas</b> sobre el comportamiento y necesidades de tus clientes para una mejora continua y progresiva de tus servicios.<br>Da un paso ahora al siguiente nivel en el desarrollo de tu sitio web.'
            }
        ],
        logo: require('@/assets/img/kkweb.svg'),
        img: require('@/assets/img/contact.jpg'),
        indicator: '- CONTACTO - CONTACT -',
        title: 'Contacta ahora con Kraken Labs',
        mailEndopint: 'https://us-central1-chemaalfonsoweb.cloudfunctions.net/myMailSender'
      }
  },
  methods: {
    sendWhatsApp() {
      if( window.gtag ) {
          window.gtag('event', 'sendWhatsapp', {'event_category': 'acciones', 'event_label': 'Envíar WhatsApp directo', 'value': '0'});
      }

      const link = `https://api.whatsapp.com/send?phone=622409597&text=${ (this.$refs.whatsappField as HTMLInputElement).value }`;
      window.open( link, '_blank', `width=${ window.innerWidth / 2 }, height=${ window.innerHeight / 2 }, left=0, top=${ window.innerHeight / 4 }`)
    },
    async sendMail( mail: string ) {
      return await fetch( this.$data.mailEndpoint + `?email=${ mail }` )
      .then( resp => resp.json() );
    },
    formSubmit( useExternalMail = true ) {

      if( window.gtag ) {
          window.gtag('event', 'sendMail', {'event_category': 'acciones', 'event_label': 'Envíar mail de contacto', 'value': '0'});
      }

      if ( useExternalMail ) {
        const mailLink: HTMLAnchorElement = document.createElement('a')
        mailLink.setAttribute('href', 'mailto:hola@krakenlabsweb.com?cc=&bcc=hola@chemaalfonso.com&subject=Solicitud%20web&body=Hola,%20estaba%20interesado%20en%20un%20servicio%20web,%20%20')
        mailLink.click()
        return
      }
      
      const loaderSwal = Swal;
      loaderSwal.fire({
          title: 'Cargando',
          text: `Enviando el mensaje...`,
          icon: 'info',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
      });

      if( (this.$refs.emailField as HTMLInputElement).value.length > 0 ) {
        this.sendMail( (this.$refs.emailField as HTMLInputElement).value ).then( resp => {
            loaderSwal.close();
            if( resp.ok ) {
                Swal.fire({
                    title: 'Gracias!!',
                    text: `Mensaje enviado correctamente, te contactaré lo antes posible.`,
                    icon: 'success',
                    confirmButtonText: 'Genial!'
                });
            } else {
                Swal.fire({
                    title: 'Ups...',
                    html: "No se ha podido enviar el email, por favor, prueba de nuevo en unos momentos.<br><br>También puedes:<br><br> <a class='dark-link' href='tel:+34622409597'><i class='fa fa-whatsapp fa-2x text-success' aria-hidden='true'></i> Enviar un WhatsApp</a> <br><br><a class='dark-link' href='tel:+34622409597'><i class='fa fa-phone fa-2x text-primary'aria-hidden='true'></i> Hacer una llamada</a> ",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        })
        .catch( () => {
            loaderSwal.close();
            Swal.fire({
                title: 'Ups...',
                    html: "No se ha podido enviar el email, por favor, prueba de nuevo en unos momentos.<br><br>También puedes:<br><br> <a class='dark-link' href='tel:+34622409597'><i class='fa fa-whatsapp fa-2x text-success' aria-hidden='true'></i> Enviar un WhatsApp</a> <br><br><a class='dark-link' href='tel:+34622409597'><i class='fa fa-phone fa-2x text-primary'aria-hidden='true'></i> Hacer una llamada</a> ",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        });
      }
    }
  
  }
})
