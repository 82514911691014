














import Vue from 'vue';
import KkCard from '@/components/parts/KkCard.vue';
import Footer from '@/components/parts/Footer.vue';
import ContactBanner from '@/components/interaction/ContactBanner.vue';
import { privacyTexts } from '@/data/privacy.ts';

export default Vue.extend({
  name: 'Privacy',
  components: {
    KkCard,
    Footer,
    ContactBanner
  },
  data() {
      return {
          tempTexts: privacyTexts,
          logo: require('@/assets/img/kkweb.svg'),
          img: require('@/assets/img/locker.jpg'),
          indicator: '- PRIVACY - PRIVACIDAD -'
      }
  }
})
