














import Vue from 'vue';
import KkCard from '@/components/parts/KkCard.vue';
import Footer from '@/components/parts/Footer.vue';
import ContactBanner from '@/components/interaction/ContactBanner.vue';
import { cookiesTexts } from '@/data/cookies.ts';

export default Vue.extend({
  name: 'Cookies',
  components: {
    KkCard,
    Footer,
    ContactBanner
  },
  data() {
      return {
          texts: cookiesTexts,
          logo: require('@/assets/img/kkweb.svg'),
          img: require('@/assets/img/nojunkm.jpg'),
          indicator: '- COOKIES - COOKIES -'
      }
  }
})
