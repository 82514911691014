






























import Vue from 'vue';

export default Vue.extend({
    name: 'KkCard',
    props: {
        logo: String,
        title: String,
        textBlocks: Array,
        blockImg: String,
        blockImgTitle: String,
        pageIndicator: String
    },
    methods: {
        navigateBack() {
            this.$router.go(-1);
        }
    }
})
