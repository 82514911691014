














import Vue from 'vue';
import KkCard from '@/components/parts/KkCard.vue';
import Footer from '@/components/parts/Footer.vue';
import ContactBanner from '@/components/interaction/ContactBanner.vue';

export default Vue.extend({
  name: 'Services',
  components: {
    KkCard,
    Footer,
    ContactBanner
  },
  data() {
      return {
          tempTexts: [
              {
                  title: 'Diseño web avanzado a medida',
                  desc: 'El diseño web moderno requiere mucho más que símplemente un sitio atractivo. Aspectos como la <b>experiencia de usuario</b>, <b>optimización para motores de búsqueda</b>, <b>facilidad de uso</b> entre otros detalles técnicos son imprescindibles a la hora de optimizar los recursos y obtener un sitio web competitivo a hoy en día.'
              },
              {
                  title: 'Diseño responsive',
                  desc: '<b>Más del 50% de todo el tráfico de la web en la actualidad proviene de dispositivos móviles</b>, una web adaptable ya es una necesidad.<br><br>Un diseño web responsive permite que su sitio web se adapte y proporcione una experiencia óptima en todos los dispositivos, por lo que se verá muy bien y <b>funcionará sin problemas tanto en escritorio, portátil, tableta como en teléfonos móviles</b>.'
              },
              {
                  title: 'Apps web multidispositivo',
                  desc: 'Teniendo en cuenta la gran cantidad de conexiones que se realizan hoy día mediante dispositivos móviles, podemos ir un paso más allá haciendo <b>tu sitio web instalable en dispositivos móviles</b> para poder usarla como si de una app se tratara. <br> Obtendrás la comodidad de tener siempre tu web instalada en el dispositivo para gestionarla y para mayor fidelización y atrativo por parte de los usuarios.'
              },
              {
                  title: 'Tiendas Online y servicios de ventas',
                  desc: 'Aprovecha las nuevas tecnologías y obtén tu tienda online de <b>productos físicos o digitales disponible para tus clientes 24/7</b>, con <b>analíticas y registros de actividad</b> para llevar un control preciso de cada movimiento realizado en tu tienda.'
              },
              {
                  title: 'Convierte visitantes en clientes',
                  desc: 'Establece como conversiones los aspectos más relevantes de tu tienda y <b>determina cuales son las acciones más importantes para tu actividad</b>. Posteriormente y con ese objetivo en mente se planificará el desarrollo enfocado en el alcance de estos objetivos que podrán ser medibles mediante <b>analíticas detalladas</b>.'
              },
              {
                  title: 'Servicio de apoyo a empresas',
                  desc: 'Si dispones de una agencia de desarrollo y necesitas apoyo en tus proyectos <b>podemos ayudarte a la creación de distintos proyectos en función de tus necesidades</b>. Ofrece trabajos de alta calidad a tus clientes y forma parte del desarrollo de Kraken Labs.'
              }
          ],
          logo: require('@/assets/img/kkweb.svg'),
          img: require('@/assets/img/setup.jpg'),
          indicator: '- SERVICES - SERVICIOS -'
      }
  }
})
